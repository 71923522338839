import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import { addJot } from '../context/GlobalActions'
import { forEach, uniq } from 'lodash'
import { obscureTrashName } from '../defaultData'
import Header from './Header'

function AddJotForm() {
  const { jots } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    group: 'default',
    newGroup: '',
  })
  const [allGroups, setAllGroups] = useState([])
  const [message, setMessage] = useState('')
  const { title, group, newGroup } = formData

  useEffect(() => {
    setMessage('')
    let allGroupsArr = []
    forEach(jots, (jotObj, key) => {
      const group = jotObj.group
      if (group !== obscureTrashName) allGroupsArr.push(group)
    })
    allGroupsArr = uniq(allGroupsArr).sort()
    setAllGroups(allGroupsArr)
  }, [jots])

  const onSubmit = async e => {
    e.preventDefault()
    setMessage('')
    console.log('formData: ', formData)
    const jotGroup = newGroup ? newGroup : group
    console.log('jotGroup: ', jotGroup)
    if (jotGroup === 'default') {
      setMessage(
        'Either select a group from "Choose a group" or enter a "New group" name.'
      )
      return
    }
    try {
      const jotId = await addJot(title, jotGroup)
      console.log('jotId: ', jotId)
      navigate(`/gotojot/${jotId}`)
    } catch (error) {
      console.log('Save new jot error:', error)
      setMessage('Jot did not save.')
    }
  }

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }))
  }

  return (
    <>
      <Header />
      <div className='flex flex-col items-center'>
        <p className='text-3xl pt-6'>Add New Jot</p>
        <form className='flex flex-col items-center' onSubmit={onSubmit}>
          <div className='pt-3'>
            <input
              required
              autoFocus
              id='title'
              type='text'
              className='text-black w-60'
              placeholder='Title'
              value={title}
              onChange={onChange}
            />
          </div>
          <div className='pt-3'>
            <select
              id='group'
              className='text-black w-60'
              disabled={newGroup ? true : false}
              onChange={onChange}
            >
              <option value='default' hidden>
                Choose a group
              </option>
              {allGroups.map((group, i) => {
                return (
                  <option value={group} key={i}>
                    {group}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='pt-3'>
            <input
              id='newGroup'
              type='text'
              className='text-black w-60'
              placeholder='New group'
              value={newGroup}
              onChange={onChange}
            />
          </div>
          <div className='pt-4'>
            <button className='bg-success px-6 py-4 rounded'>
              <span className='text-white text-xl'>Add Jot</span>
            </button>
          </div>
        </form>
        {message && <p className='pt-4 text-xl text-danger'>{message}</p>}
      </div>
    </>
  )
}

export default AddJotForm
