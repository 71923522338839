import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'

function NavJotTitles({ jots }) {
  const { jotId } = useParams()
  const { dispatch } = useContext(GlobalContext)

  const onDragStart = ({ jot, e }) => {
    console.log('NavJotTitles.jsx - onDragStart()')
    // const jotString = JSON.stringify(jot);
    // e.dataTransfer.setData("jotString", jotString);
    // e.dataTransfer.setData("jotGroup", jot.group);
    // TODO - Change this to the native API methods.
    dispatch({ type: 'SET_DRAGGED_JOT', payload: jot })
  }

  return (
    <>
      {jots.map(jot => {
        return (
          <Link
            to={`gotojot/${jot.id}`}
            key={jot.id}
            className='p-2 cursor-pointer'
          >
            <p
              draggable='true'
              onDragStart={e => onDragStart({ jot, e })}
              className={`pl-2 leading-tight ${
                jot.id === jotId ? 'font-bold text-menu' : ''
              }`}
            >
              {jot.title}
            </p>
          </Link>
        )
      })}
    </>
  )
}

export default NavJotTitles
