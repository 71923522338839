import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import { FaRedo } from 'react-icons/fa'

function LastJot() {
  const { jots, settings } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [isLastJot, setIsLastJot] = useState(false)
  const [lastJotTitle, setLastJotTitle] = useState('')

  useEffect(() => {
    if (jots[settings.lastJotId] && jots[settings.lastJotId]['title']) {
      setIsLastJot(true)
      setLastJotTitle(jots[settings.lastJotId]['title'])
    }
    // eslint-disable-next-line
  }, [settings.lastJotId])

  const displayLastJot = () => {
    console.log('LastJot.jsx - displayLastJot()')
    if (isLastJot) {
      navigate(`/gotojot/${settings.lastJotId}`)
    }
  }

  return (
    <>
      <FaRedo
        style={{ transform: 'scaleX(-1)' }}
        fill={lastJotTitle ? '#3b82f6' : '#555'}
        title={
          isLastJot
            ? `Previous jot: ${lastJotTitle}`
            : 'After viewing a jot, use this button to go to the previous jot.'
        }
        className={isLastJot ? `cursor-pointer` : ''}
        onClick={displayLastJot}
      />
    </>
  )
}

export default LastJot
