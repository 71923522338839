import { createContext, useReducer } from 'react'
import globalReducer from './GlobalReducer'
import { globalContextInitialState } from '../defaultData'

const GlobalContext = createContext()

export const GlobalProvider = ({ children }) => {
  const initialState = globalContextInitialState

  const [state, dispatch] = useReducer(globalReducer, initialState)

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalContext
