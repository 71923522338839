import { useContext } from 'react'
import GlobalContext from '../../context/GlobalContext'
import { FaPlay } from 'react-icons/fa'

function ToggleMenuIcon() {
  const { dispatch, isMenuCollapse } = useContext(GlobalContext)

  const toggleIsMenuCollapse = () => {
    let toggledValue = !isMenuCollapse
    dispatch({
      type: 'SET_IS_MENU_COLLAPSE',
      payload: toggledValue,
    })
  }

  return (
    <div
      className='absolute top-0 left-0 pb-3 pr-3 cursor-pointer'
      onClick={toggleIsMenuCollapse}
    >
      <FaPlay
        fill='#555'
        size='1.2rem'
        className={`mt-2 ml-0.5 ${isMenuCollapse ? '' : 'rotate-180'}`}
      />
    </div>
  )
}

export default ToggleMenuIcon
