import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { GlobalProvider } from './context/GlobalContext'
import {} from './firebaseInit'
import AuthAndDataAndColor from './components/auth/AuthAndDataAndColor'
import Landing from './pages/Landing'
import Home from './pages/Home'
import GotoJot from './components/GotoJot' // Deals with Quill bug.
import Jot from './components/Jot'
import AddJotForm from './components/AddJotForm'
import Index from './components/Index'

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <AuthAndDataAndColor>
                <Landing />
              </AuthAndDataAndColor>
            }
          >
            <Route index element={<Index />} />
            <Route path='gotojot/:jotId' element={<GotoJot />} />
            <Route path='jot/:jotId' element={<Jot />} />
            <Route path='addJot' element={<AddJotForm />} />
          </Route>
          <Route path='/home' element={<Home />} />
          <Route path='/*' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  )
}

export default App
