import { Link } from 'react-router-dom'
import Logo from '../components/icons-logo/Logo'

function NavHead() {
  return (
    <Link
      to='/'
      className='h-20 custom-min-h-20 flex flex-row items-center justify-center bg-header dark:bg-dark-header cursor-pointer'
    >
      <Logo padding='p-2' size='48px' isBackground={false} />
      <p className='ml-3 text-3xl whitespace-nowrap font-thin'>SpeedJots</p>
    </Link>
  )
}

export default NavHead
