import AddJotButton from '../components/icons-logo/AddJotButton'
import SearchJots from '../components/SearchJots'
import LastJot from '../components/LastJot'
import LastXJots from '../components/LastXJots'

function NavUtilities() {
  return (
    <>
      <div className='p-3 flex flex-row justify-evenly items-center'>
        <LastXJots />
        <AddJotButton size='1.5rem' />
        <LastJot />
      </div>
      <SearchJots />
    </>
  )
}
export default NavUtilities
