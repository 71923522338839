import { useContext, useState } from 'react'
import GlobalContext from '../context/GlobalContext'
import { jotsObjToOrderedArr } from '../context/GlobalActions'
import { FaTimes, FaFileMedical } from 'react-icons/fa'
import { forEach, includes, isEmpty } from 'lodash'
import NavJotTitles from './NavJotTitles'

function SearchJots() {
  const { jots, settings } = useContext(GlobalContext)
  const [searchStr, setSearchStr] = useState('')
  const [isSearchContent, setIsSearchContent] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  const onChange = e => {
    setSearchStr(e.target.value)
    console.log('searchStr.length: ', searchStr.length)
    let jotsArr = []
    let jotsFound = {}
    if (searchStr.length > 1) {
      forEach(jots, (jot, jotId) => {
        if (
          includes(jot.title, searchStr) ||
          includes(jot.title.toLowerCase(), searchStr.toLowerCase())
        ) {
          jotsFound[jotId] = jot
        }
        if (
          isSearchContent &&
          (includes(jot.content, searchStr) ||
            includes(jot.content.toLowerCase(), searchStr.toLowerCase()))
        ) {
          jotsFound[jotId] = jot
        }
      })
      jotsArr = jotsObjToOrderedArr(jotsFound, settings)
    }
    setSearchResults(jotsArr)
  }

  const clearSearch = () => {
    setSearchStr('')
    setSearchResults({})
  }

  return (
    <>
      <div className='flex flex-row items-center justify-center m-2 mt-0'>
        <input
          id='searchStr'
          type='text'
          className='w-36 text-black bg-light-grey p-0 pl-1'
          placeholder='Search ...'
          value={searchStr}
          onChange={onChange}
        />
        <FaTimes
          className='ml-2 cursor-pointer'
          title='Clear search'
          fill={searchStr.length > 0 ? '#dc2626' : '#555'}
          size='1rem'
          onClick={clearSearch}
        />
        <FaFileMedical
          className='ml-2 cursor-pointer'
          title='Search jot content also.'
          fill={isSearchContent ? '#16a34a' : '#555'}
          size='1rem'
          onClick={() => setIsSearchContent(prev => !prev)}
        />
      </div>
      {!isEmpty(searchResults) ? (
        <div className='m-1 border-y border-menu'>
          <NavJotTitles jots={searchResults} />
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default SearchJots
