function Privacy() {
  return (
    <>
      <p className='text-xl text-center pt-8 pb-2'> Privacy Policy </p>
      <p className='pt-1'>
        SpeedJots does not do anything with your data other than serve it back
        to you.
      </p>
      <p className='pt-1'>
        SpeedJots does not rent, sell or give away any data ... ever.
      </p>
      <p className='pt-1'>
        The only personal data SpeedJots stores (other than your Jots) is your
        login email.{' '}
      </p>
    </>
  )
}

export default Privacy
