import { push, ref, set } from 'firebase/database'
import { auth, db } from '../firebaseAssets'
import { isEmpty, orderBy, uniq } from 'lodash'
import { defaultSettings } from '../defaultData'

export const setAllJots = async jots => {
  console.log('GlobalActions.js - setAllJots()')
  if (isEmpty(jots)) throw new Error('jots obj is empty!')
  const userId = auth.currentUser.uid
  const path = `users/${userId}/jots`
  const jotsRef = ref(db, path)
  try {
    return await set(jotsRef, jots)
  } catch (e) {
    return console.log('GlobalActions.js - setAllJots() error: ', e)
  }
}

export const addJot = async (title, group) => {
  console.log('GlobalActions.js - addJot()')
  if (!title || !group) throw new Error('No jot title or group set.')
  const userId = auth.currentUser.uid
  const timeNow = new Date().getTime()
  const newJot = {
    title: title,
    group: group,
    content: '<p>Type wisdom here ...</p>',
    dateCreated: timeNow,
    inTrash: false,
  }
  const path = `users/${userId}/jots/`
  const jotRef = ref(db, path)
  try {
    const ref = await push(jotRef, newJot)
    return ref.key
  } catch (e) {
    return console.log('GlobalActions.js - addJot() error: ', e)
  }
}

export const updateJot = async (jot, jotId) => {
  console.log('GlobalActions.js - updateJot()')
  if (isEmpty(jot) || !jotId) throw new Error('jot or jotId is empty!')
  jot.dateLastUpdate = new Date().getTime()
  delete jot.isDirty
  const userId = auth.currentUser.uid
  const path = `users/${userId}/jots/${jotId}`
  const jotRef = ref(db, path)
  try {
    return await set(jotRef, jot)
  } catch (e) {
    return console.log('GlobalActions.js - updateJot() error: ', e)
  }
}

export const updateJotTitle = async (jot, jotId, newTitle) => {
  console.log('GlobalActions.js - updateJotTitle()')
  if (isEmpty(jot) || !jotId || !newTitle)
    throw new Error('jot or jotId or newTitle is empty!')
  const userId = auth.currentUser.uid
  jot['dateLastUpdate'] = new Date().getTime()
  jot['title'] = newTitle
  const path = `users/${userId}/jots/${jotId}`
  const jotRef = ref(db, path)
  try {
    return await set(jotRef, jot)
  } catch (e) {
    return console.log('GlobalActions.js - updateJotTitle() error: ', e)
  }
}

export const updateJotGroup = async (jot, newGroup) => {
  console.log('GlobalActions.js - updateJotGroup()')
  if (isEmpty(jot) || !newGroup)
    throw new Error('jot or jotId or newGroup is empty!')
  const userId = auth.currentUser.uid
  const jotId = jot.id
  jot['group'] = newGroup
  jot['dateLastUpdate'] = new Date().getTime()
  const path = `users/${userId}/jots/${jotId}`
  const jotRef = ref(db, path)
  try {
    return await set(jotRef, jot)
  } catch (e) {
    return console.log('GlobalActions.js - updateJotGroup() error: ', e)
  }
}

export const setJotTrash = async (jotId, value) => {
  console.log('GlobalActions.js - setJotTrash()')
  if (!jotId) throw new Error('jotId is not set!')
  const userId = auth.currentUser.uid
  const path = `users/${userId}/jots/${jotId}/inTrash`
  const jotInTrashRef = ref(db, path)
  try {
    return await set(jotInTrashRef, value)
  } catch (e) {
    return console.log('GlobalActions.js - setJotTrash() error: ', e)
  }
}

export const deleteJot = async jotId => {
  console.log('GlobalActions.js - deleteJot()')
  if (!jotId) throw new Error('jotId is not set!')
  const userId = auth.currentUser.uid
  const path = `users/${userId}/jots/${jotId}`
  const jotRef = ref(db, path)
  try {
    return await set(jotRef, null)
  } catch (e) {
    return console.log('GlobalActions.js - deleteJot() error: ', e)
  }
}

export const updateLastJotId = async jotId => {
  console.log('GlobalActions.js - updateLastJotId()')
  const userId = auth.currentUser.uid
  const lastJotIdRef = ref(db, `users/${userId}/settings/lastJotId`)
  try {
    return await set(lastJotIdRef, jotId)
  } catch (e) {
    return console.log('GlobalActions.js - updateLastJotId() error: ', e)
  }
}

export const updateLastXJotsIds = async ({ jotId, lastXJotsIds = [] }) => {
  console.log('GlobalActions.js - updateLastXJotsIds()')
  if (jotId) {
    const userId = auth.currentUser.uid
    const lastXJotsIdRef = ref(db, `users/${userId}/settings/lastXJotsIds`)
    lastXJotsIds.unshift(jotId)
    if (lastXJotsIds.length > 10) {
      lastXJotsIds.pop()
    }
    lastXJotsIds = uniq(lastXJotsIds)
    const lastXJotsObj = {}
    lastXJotsIds.forEach((id, i) => {
      lastXJotsObj[i] = id
    })
    try {
      return await set(lastXJotsIdRef, lastXJotsObj)
    } catch (e) {
      return console.log('GlobalActions.js - updateLastXJotsIds() error: ', e)
    }
  }
  return
}

// export const deleteAllUserData = async (userId) => {
//   console.log("GlobalActions.js - deleteAllUserData()");
//   const userRef = ref(db, `users/${userId}`);
//   try {
//     return await set(userRef, null);
//   } catch (e) {
//     return console.log("GlobalActions.js - deleteAllUserData() error: ", e);
//   }
// };

export const changeTheme = value => {
  console.log('GlobalActions.js - changeTheme()')
  const userId = auth.currentUser.uid
  const isDarkRef = ref(db, `users/${userId}/settings/isDark`)
  set(isDarkRef, value).catch(e =>
    console.log('GlobalActions.js - changTheme() error: ', e)
  )
}

export const changeOrderJotsBy = orderBy => {
  console.log('GlobalActions.js - changeOrderJotsBy()')
  const userId = auth.currentUser.uid
  const newOrderBy = orderBy === 'title' ? 'dateLastUpdate' : 'title'
  const orderJotsByRef = ref(db, `users/${userId}/settings/orderJotsBy`)
  set(orderJotsByRef, newOrderBy).catch(e =>
    console.log('GlobalActions.js - changeOrderJotsBy() error: ', e)
  )
}

export const jotsObjToOrderedArr = (jots, settings) => {
  console.log('GlobalActions.js - jotsObjToOrderedArr()')
  const sortBy = settings.orderJotsBy
    ? settings.orderJotsBy
    : defaultSettings.settings.orderJotsBy
  let jotsArr = []
  Object.keys(jots).forEach(jotId => {
    jotsArr.push({ ...jots[jotId], id: jotId, content: null })
  })
  const orderedJotsArr = orderBy(jotsArr, ['group', sortBy])
  return orderedJotsArr
}
