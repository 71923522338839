import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const GotoJot = () => {
  const { jotId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/jot/${jotId}`)
  })

  return null
}

export default GotoJot
