import { Link } from 'react-router-dom'
import { FaPlusCircle } from 'react-icons/fa'

function AddJot({ size }) {
  return (
    <Link to='/addJot' title='Add a new Jot'>
      <FaPlusCircle size={size} fill='#168534' />
    </Link>
  )
}

export default AddJot
