import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import {
  updateJotGroup,
  setAllJots,
  setJotTrash,
} from '../context/GlobalActions'
import { isEmpty, includes } from 'lodash'
import {
  FaAngleRight,
  FaAngleDown,
  FaRegEdit,
  FaCheck,
  FaTimes,
} from 'react-icons/fa'
import NavJotTitles from '../components/NavJotTitles'
import { obscureTrashName } from '../defaultData'

function NavGroup({ group, groupJots }) {
  const { jotId } = useParams()
  const { dispatch, jots, draggedJot } = useContext(GlobalContext)
  const [editGroupName, setEditGroupName] = useState('')
  const [newGroupName, setNewGroupName] = useState('')
  const [showGroup, setShowGroup] = useState(false)

  useEffect(() => {
    // console.log("NavGroup.jsx - useEffect() [jotId, groupJots]");
    const allJotIds = groupJots.map(jot => jot.id)
    if (includes(allJotIds, jotId)) {
      setShowGroup(true)
    }
  }, [jotId, groupJots])

  const saveGroupNameChange = () => {
    console.log('NavGroup.jsx - saveGroupNameChange()')
    Object.keys(jots).forEach(jotId => {
      if (jots[jotId]['group'] === editGroupName) {
        jots[jotId]['group'] = newGroupName
      }
    })
    setAllJots(jots)
      .then(() => {
        setEditGroupName('')
        setNewGroupName('')
      })
      .catch(e => {
        console.log(
          'NavGroup.jsx - saveGroupNameChange() - setAllJots() error: ',
          e
        )
      })
  }

  const onDragEnter = ({ group, wrapperId }) => {
    console.log('NavGroup.jsx - onDragEnter()')
    if (draggedJot && draggedJot.group !== group) {
      const wrapperEl = document.getElementById(wrapperId)
      wrapperEl.animate({ background: ['', '#16a34a44', ''] }, 2000)
    }
  }

  const onDrop = async ({ e, group }) => {
    console.log('NavGroup.jsx - onDrop()')
    e.preventDefault()
    if (
      draggedJot &&
      draggedJot.group !== group &&
      group !== obscureTrashName
    ) {
      await updateJotGroup(draggedJot, group)
      dispatch({ type: 'SET_DRAGGED_JOT', payload: {} })
    } else if (group === obscureTrashName) {
      setJotTrash(draggedJot.id, true)
    }
  }

  return (
    <div
      id={`wrapper-${group}`}
      onDragOver={e => e.preventDefault()}
      onDragEnter={() => onDragEnter({ group, wrapperId: `wrapper-${group}` })}
      onDrop={e => onDrop({ e, group })}
    >
      <div
        className={`p-3 flex flex-row items-center ${
          group === obscureTrashName ? 'bg-danger' : 'bg-menu'
        }`}
      >
        {group !== obscureTrashName && !editGroupName && (
          <div
            className='mr-1 opacity-60 cursor-pointer'
            onClick={() => {
              setEditGroupName(group)
              setNewGroupName(group)
            }}
          >
            <FaRegEdit />
          </div>
        )}
        <div
          className='flex-grow text-xl cursor-pointer'
          onClick={() => {
            if (!editGroupName) setShowGroup(prev => !prev)
          }}
        >
          <div className='opacity-95'>
            {group === obscureTrashName ? (
              'Trash'
            ) : editGroupName === group ? (
              <div className='flex flex-row'>
                <input
                  type='text'
                  className='textInput text-black p-0 w-36'
                  id={group}
                  value={newGroupName}
                  onChange={e => setNewGroupName(e.target.value)}
                />
                <button className='ml-1' onClick={saveGroupNameChange}>
                  <FaCheck
                    fill={newGroupName !== editGroupName ? '#16a34a' : '#555'}
                  />
                </button>
                <button
                  className='ml-1'
                  onClick={() => {
                    setEditGroupName('')
                    setNewGroupName('')
                  }}
                >
                  <FaTimes fill='#dc2626' />
                </button>
              </div>
            ) : (
              group
            )}
          </div>
        </div>
        <div className='opacity-80 cursor-pointer'>
          {showGroup ? <FaAngleDown /> : <FaAngleRight />}
        </div>
      </div>

      <div className={showGroup ? 'pb-6' : 'hidden'}>
        {!isEmpty(groupJots) ? <NavJotTitles jots={groupJots} /> : ''}
      </div>
    </div>
  )
}

export default NavGroup
