import { auth } from '../../firebaseAssets'
import { FaAngleDown } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import UserMenuDropDown from './UserMenuDropDown'

function UserMenu() {
  const userPhotoUrl = auth.currentUser.photoURL
  const userDisplayName = auth.currentUser.displayName
  const userEmail = auth.currentUser.email

  return (
    <>
      <div data-tip data-for='user-dd-menu'>
        <div className='flex flex-col items-end cursor-pointer'>
          <div className='flex flex-col items-center'>
            {userPhotoUrl ? (
              <img
                src={userPhotoUrl}
                alt='user'
                className='w-12 rounded-full'
                referrerPolicy='no-referrer'
              />
            ) : (
              <div className='p-1 w-12 h-12 text-xl font-bold flex justify-center items-center rounded-full bg-menu border'>
                {userDisplayName
                  ? userDisplayName.slice(0, 1).toUpperCase()
                  : userEmail
                  ? userEmail.slice(0, 1).toUpperCase()
                  : 'S'}
              </div>
            )}
            <div className='-m-4'>
              <FaAngleDown fill='#fafafa' size='1.5rem' />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        id='user-dd-menu'
        place='bottom'
        effect='solid'
        offset={{ top: -5, left: 131 }}
        clickable={true}
        delayHide={500}
      >
        <UserMenuDropDown />
      </ReactTooltip>
    </>
  )
}

export default UserMenu
