import { useParams } from 'react-router-dom'
import JotTrash from './JotTrash'
import HeaderJot from './HeaderJot'
import JotQuill from './JotQuill'

function Jot() {
  const { jotId } = useParams()

  return (
    <>
      <HeaderJot jotId={jotId} />
      <div id='quill-wrapper-div' className='pb-20 flex-grow overflow-auto'>
        <JotQuill jotId={jotId} />
        <JotTrash jotId={jotId} />
      </div>
    </>
  )
}

export default Jot
