import { useContext } from 'react'
import GlobalContext from '../context/GlobalContext'
import NavHead from './NavHead'
import NavUtilities from './NavUtilities'
import NavJots from './NavJots'
import ToggleMenuIcon from '../components/icons-logo/ToggleMenuIcon'

function Nav() {
  const { isMenuCollapse } = useContext(GlobalContext)

  return (
    <>
      {isMenuCollapse ? (
        <ToggleMenuIcon />
      ) : (
        <div
          id='nav-div'
          className='sm:h-screen sm:border-r sm:border-solid sm:border-grey z-10'
        >
          <ToggleMenuIcon />
          <div className='flex flex-col max-h-screen'>
            <NavHead />
            <NavUtilities />
            <NavJots />
          </div>
        </div>
      )}
    </>
  )
}

export default Nav
