const timeNow = new Date().getTime()

export const obscureTrashName = 'ob5cur13yNam3dTr25h'

export const defaultSettings = {
  settings: {
    isDark: false,
    orderJotsBy: 'title',
    lastJotId: '',
    lastXJotsIds: '',
    saveTimerLimit: 2000,
  },
}

export const globalContextInitialState = {
  ...defaultSettings,
  jots: {},
  isMenuCollapse: false,
  displayAddJotForm: false,
  draggedJot: {},
}

export const newUserFbData = {
  ...defaultSettings,
  jots: {
    0: {
      title: 'Random musings',
      content: '<p>This is where I keep jots n sots.</p>',
      dateCreated: timeNow,
      dateLastUpdate: timeNow,
      group: 'General Jots',
      inTrash: false,
    },
  },
}
