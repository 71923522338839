import Logo from '../components/icons-logo/Logo'
import LoginEmail from '../components/auth/LoginEmail'
import LoginSocial from '../components/auth/LoginSocial'
import Privacy from '../components/subpages/Privacy'

function Home() {
  return (
    <>
      <div className='h-20 flex justify-center items-center border-b border-light-grey bg-header dark:bg-dark-header'>
        <p className='text-5xl font-light'>SpeedJots</p>
      </div>
      <div className='p-4 flex flex-col items-center'>
        <div className='pt-2'>
          <Logo padding='p-4' size='80px' />
        </div>

        <div className='w-9/12 mt-8 mb-4 border-t border-light-grey' />

        <p className='pt-4 text-xl text-center'>
          A web app designed to do one thing and do it exceptionally well.
        </p>
        <p className='pt-4 text-3xl text-center'>Organize searchable notes</p>
        <p className='pt-4 text-xl text-center'>
          SpeedJots is dedicated to remain simple with no bloat no mess no cost.
        </p>

        <div className='w-9/12 mt-10 mb-6 border-t border-light-grey' />
        <LoginSocial />

        <div className='w-5/12 mt-6 mb-6 border-t border-light-grey' />
        <LoginEmail />

        <div className='w-9/12 mt-10 mb-6 border-t border-light-grey' />
        <Privacy />

        <div className='w-9/12 h-1 mt-10 mb-4 border-b border-t border-light-grey' />
      </div>
    </>
  )
}

export default Home
