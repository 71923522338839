import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import { FaStream } from 'react-icons/fa'
import { isEmpty } from 'lodash'
import ReactTooltip from 'react-tooltip'

function LastJot() {
  const { jots, settings } = useContext(GlobalContext)
  const [isLastXJotsIds, setIsLastXJotsIds] = useState(false)
  // const [lastXJots, setLastXJots] = useState([])

  useEffect(() => {
    if (!isEmpty(settings.lastXJotsIds)) {
      setIsLastXJotsIds(true)
      // const idsToJots = settings['lastXJotsIds'].map(id => jots[id])
      // console.log('idsToJots: ', idsToJots)
      // setLastXJots(idsToJots)
    }
    // eslint-disable-next-line
  }, [settings.lastXJotsIds])

  return (
    <div data-tip data-for='last-x-jots-dd-menu'>
      <FaStream
        style={{ transform: 'scaleX(-1)' }}
        fill={isLastXJotsIds ? '#3b82f6' : '#555'}
        title={
          isLastXJotsIds
            ? `Go to previous jots ...`
            : 'After viewing a few jots, use this button to go to previous jots.'
        }
        className={isLastXJotsIds ? `cursor-pointer` : ''}
      />
      {isLastXJotsIds && (
        <ReactTooltip
          id='last-x-jots-dd-menu'
          place='bottom'
          effect='solid'
          offset={{ left: '-70%' }}
          clickable={true}
          delayHide={500}
        >
          {settings.lastXJotsIds.map(id => {
            if (jots[id]) {
              return (
                <Link
                  to={`gotojot/${id}`}
                  key={id}
                  className='p-2 cursor-pointer'
                >
                  <p className='text-base'>{jots[id]['title']}</p>
                </Link>
              )
            }
            return <></>
          })}
        </ReactTooltip>
      )}
    </div>
  )
}

export default LastJot
