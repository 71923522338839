import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GlobalContext from '../context/GlobalContext'
import { FaTrash } from 'react-icons/fa'
import {
  setJotTrash,
  deleteJot,
  updateLastJotId,
} from '../context/GlobalActions'

function JotTrash({ jotId }) {
  const { jots } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [isJotInTrash, setIsJotInTrash] = useState(false)
  const [group, setGroup] = useState('')

  useEffect(() => {
    console.log('JotTrash.jsx - useEffect()')
    setIsJotInTrash(jots[jotId]['inTrash'])
    setGroup(jots[jotId]['group'])
  }, [jots, jotId])

  const moveInToTrash = () => {
    console.log('JotTrash.jsx - moveInToTrash()')
    setJotTrash(jotId, true)
  }

  const moveOutOfTrash = () => {
    console.log('JotTrash.jsx - moveOutOfTrash()')
    setJotTrash(jotId, false)
  }

  const delJot = () => {
    console.log('JotTrash.jsx - delJot()')
    navigate('/')
    deleteJot(jotId)
      .then(() => {
        updateLastJotId('')
      })
      .catch(e => console.log(`JotTrash.jsx - delJot error: ${e}`))
  }

  return (
    <div className='mt-6'>
      {isJotInTrash ? (
        <div className='flex flex-col items-center'>
          <button
            className='bg-success mt-6 px-4 py-2 rounded cursor-pointer'
            onClick={moveOutOfTrash}
          >
            <span className='text-white text-xl'>
              Move back to:
              <span className='font-bold'> {group}</span>
            </span>
          </button>
          <button
            className='bg-danger mt-6 px-4 py-2 rounded cursor-pointer'
            onClick={delJot}
          >
            <span className='text-white text-xl'>Delete Forever</span>
          </button>
        </div>
      ) : (
        <div className='mr-4 flex flex-row justify-end'>
          <FaTrash
            fill='#dc2626'
            title='Move jot to trash.'
            className='cursor-pointer'
            onClick={moveInToTrash}
          />
        </div>
      )}
    </div>
  )
}

export default JotTrash
