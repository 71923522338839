/*
    isDirty useState - Only used to display red/green checkmark.
    currentJot.isDirty - A property of each jot.
      Used to save jots that are missed by the debouncer. E.g. if
        user becomes disconnected from the internet.
      Only exists in global context (i.e. not in the Firebase db).
    currentContent useState - Only used by Quill to prevent bugs.
*/

import { useContext, useEffect, useState, useCallback } from 'react'
import GlobalContext from '../context/GlobalContext'
import {
  updateJot,
  updateLastJotId,
  updateLastXJotsIds,
} from '../context/GlobalActions'
import ReactQuill from 'react-quill'
import { debounce, forOwn } from 'lodash'
import { FaCheckCircle } from 'react-icons/fa'
import 'react-quill/dist/quill.snow.css'

const quillModules = {
  toolbar: [
    [
      { header: '1' },
      { header: '2' },
      { size: [] },
      { align: [] },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [
      'code-block',
      { list: 'bullet' },
      { list: 'ordered' },
      'blockquote',
      'link',
    ],
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'super' },
      { script: 'sub' },
    ],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    ['clean'],
  ],
  history: {
    userOnly: true,
  },
}

function JotQuill({ jotId }) {
  const { dispatch, jots, settings } = useContext(GlobalContext)
  const currentJot = jots[jotId]

  const [currentContent, setCurrentContent] = useState(currentJot.content)
  const [isDirty, setIsDirty] = useState(currentJot.isDirty)
  const [isInTrash] = useState(currentJot.inTrash)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveJot = useCallback(
    debounce(newContent => {
      console.log('  bebounce fired ...')
      saveJotContent({ id: jotId, jot: currentJot, newContent: newContent })
    }, settings.saveTimerLimit),
    []
  )

  useEffect(() => {
    console.log('Jots updated from onValue() in AuthAndDataAndColor.jsx.')
    setCurrentContent(currentJot.content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jots])

  useEffect(() => {
    console.log('JotQuill.jsx - useEffect() [jotId]')
    // Ensure all dirty jots are saved in case the prev one was not.
    saveAllDirtyJots()

    return () => {
      console.log('\nJotQuill.jsx - CLEAN-UP')
      updateLastJotId(jotId)
      updateLastXJotsIds({ jotId: jotId, lastXJotsIds: settings.lastXJotsIds })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jotId])

  const saveAllDirtyJots = () => {
    forOwn(jots, (jot, id) => {
      if (jot.isDirty) {
        console.log('saveAllDirtyJots(): jot isDirty -> globals updateJot()..')
        updateJot(jot, id).catch(e =>
          console.log('JotQuill.jsx - Jot did not save. Error: ', e)
        )
      }
    })
  }

  const onChange = newContent => {
    console.log('JotContent.jsx - onChange()')
    setCurrentContent(newContent)
    setIsDirty(true)
    dispatch({
      type: 'SET_JOT_IS_DIRTY',
      payload: { id: jotId, value: true },
    })
    debouncedSaveJot(newContent)
  }

  const saveJotContent = ({ jot, id, newContent }) => {
    console.log('JotQuill.jsx - saveJotContent()')
    jot.content = newContent
    updateJot(jot, id)
      .then(() => {
        setIsDirty(false)
        dispatch({
          type: 'SET_JOT_IS_DIRTY',
          payload: { id: jotId, value: false },
        })
        console.log('  successfully saved.')
      })
      .catch(e => console.log('JotQuill.jsx - Jot did not save. Error: ', e))
    // }
  }

  return (
    <>
      <div className='fixed right-2 bottom-4 sm:bottom-auto sm:top-20 pt-4 flex flex-col items-end z-10'>
        <FaCheckCircle
          fill={isDirty ? '#ff2d2d' : '#1bc057'}
          className='text-2xl'
        />
        {isInTrash && (
          <div className='bg-danger px-2 mt-3'>
            <p className='text-white text-center'>This jot is in the trash.</p>
          </div>
        )}
      </div>
      <ReactQuill
        className='border-b border-menu dark:border-dark-menu'
        /* Fix scroll after paste bug: https://github.com/quilljs/quill/issues/2027#issuecomment-600793529
           scrollingContainer is required! */
        scrollingContainer='#quill-wrapper-div'
        preserveWhitespace={true}
        theme='snow'
        modules={quillModules}
        value={currentContent}
        onChange={onChange}
      />
    </>
  )
}

export default JotQuill

// All options
// const quillModules = {
//   toolbar: [
//     [{ font: [] }, { size: [] }, { header: [1, 2, false] }],
//     ["bold", "italic", "underline", "strike"],
//     [{ color: [] }, { background: [] }],
//     [{ script: "super" }, { script: "sub" }],
//     [{ header: "1" }, { header: "2" }, "blockquote", "code-block"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//     ["direction", { align: [] }],
//     ["link", "image", "video", "formula"],
//     ["clean"],
//   ],
// };
