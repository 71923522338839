import { initializeApp } from 'firebase/app'

const isProduction = true

// Development
const firebaseConfigDev = {
  apiKey: 'AIzaSyD6vTFAtiL_3gZN9J5Wzu4phkuak05mCO0',
  authDomain: 'speedjotsdev.firebaseapp.com',
  databaseURL: 'https://speedjotsdev.firebaseio.com',
  projectId: 'speedjotsdev',
  storageBucket: 'speedjotsdev.appspot.com',
  messagingSenderId: '806645394657',
  appId: '1:806645394657:web:36e39fe0a6b6a07c741ba0',
}

// Production
const firebaseConfigProd = {
  apiKey: 'AIzaSyBGFRaMp3XIbJxXq_f8WoAxkwYEttedctg',
  authDomain: 'speed-jots.firebaseapp.com',
  databaseURL: 'https://speed-jots.firebaseio.com',
  projectId: 'speed-jots',
  storageBucket: 'speed-jots.appspot.com',
  messagingSenderId: '931687315620',
  appId: '1:931687315620:web:e5a79369653a73f2184e31',
}

const firebaseConfig = isProduction ? firebaseConfigProd : firebaseConfigDev

initializeApp(firebaseConfig)
