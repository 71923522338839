const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_IS_MENU_COLLAPSE':
      return {
        ...state,
        isMenuCollapse: action.payload,
      }

    case 'SET_USER_DATA':
      return {
        ...state,
        ...action['payload'],
      }

    case 'SET_JOT_IS_DIRTY':
      const id = action.payload.id
      const value = action.payload.value
      const jots = { ...state.jots }
      jots[id]['isDirty'] = value
      return {
        ...state,
        ...jots,
      }

    // TODO - go away
    case 'SET_DRAGGED_JOT':
      return {
        ...state,
        draggedJot: action.payload,
      }

    default:
      return state
  }
}

export default globalReducer
