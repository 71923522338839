import { useState } from 'react'
import { FaInfo, FaQuestion } from 'react-icons/fa'
import Logo from './icons-logo/Logo'
import Instructions from './subpages/Instructions'
import About from './subpages/About'
import AddJotButton from './icons-logo/AddJotButton'
import Header from './Header'

function Index() {
  const [showInstructions, setShowInstructions] = useState(false)
  const [showAbout, setShowAbout] = useState(false)

  return (
    <>
      <Header />
      <div className='flex-grow overflow-auto'>
        <div className='p-10 w-full flex flex-col items-center'>
          <Logo padding='p-4' size='80px' />
          <div className='pt-10'>
            <AddJotButton size='3rem' />
          </div>
          <div className='pt-10 flex flex-row justify-center'>
            <FaQuestion
              size='3rem'
              fill='#3b82f6'
              title='Instructions'
              className='cursor-pointer'
              onClick={() => {
                setShowInstructions(!showInstructions)
                setShowAbout(false)
              }}
            />
            <FaInfo
              size='3rem'
              fill='#1e3a8a'
              className='ml-20 cursor-pointer'
              title='About & Privacy'
              onClick={() => {
                setShowAbout(!showAbout)
                setShowInstructions(false)
              }}
            />
          </div>
          {showInstructions && <Instructions />}
          {showAbout && <About />}
        </div>
      </div>
    </>
  )
}

export default Index
