import { useContext, useState, useEffect } from 'react'
import GlobalContext from '../context/GlobalContext'
import { jotsObjToOrderedArr } from '../context/GlobalActions'
import { groupBy } from 'lodash'
import NavGroup from '../components/NavGroup'
import { obscureTrashName } from '../defaultData'

function NavJots() {
  const { jots, settings } = useContext(GlobalContext)
  const [groupedJots, setGroupedJots] = useState({})

  useEffect(() => {
    console.log('NavJots.jsx - useEffect()')
    let jotsArr = jotsObjToOrderedArr(jots, settings)
    const trashedJots = groupBy(jotsArr, jot => jot.inTrash)
    const notTrashedJots = groupBy(trashedJots.false, jot => jot.group)
    const groupedJots = { ...notTrashedJots }
    if (trashedJots.true) {
      groupedJots[obscureTrashName] = trashedJots.true
    }
    setGroupedJots(groupedJots)
    // eslint-disable-next-line
  }, [jots, settings.orderJotsBy])

  return (
    <div className='mt-4 flex-grow overflow-auto'>
      {Object.entries(groupedJots).map(([group, groupJots], i) => {
        return <NavGroup key={i} group={group} groupJots={groupJots} />
      })}
    </div>
  )
}

export default NavJots
