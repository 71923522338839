import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'
import UserMenu from '../components/user-settings/UserMenu'

function Landing() {
  useEffect(() => {
    console.log('Landing.jsx - useEffect()[]')
  }, [])

  return (
    <div className='flex flex-col sm:flex-row relative'>
      <Nav />
      <div className='flex flex-col flex-grow max-h-screen'>
        <Outlet />
      </div>
      <div className='absolute top-4 right-3 z-20'>
        <UserMenu />
      </div>
    </div>
  )
}

export default Landing
