function Header() {
  return (
    <div className='p-1 h-20 max-h-20 custom-min-h-20 w-full flex flex-row justify-center items-center bg-header dark:bg-dark-header'>
      <p className='text-4xl tracking-widest whitespace-nowrap font-thin'>
        SpeedJots
      </p>
    </div>
  )
}

export default Header
