import { FaSpinner } from 'react-icons/fa'

function Spinner({ size = '1rem', fill = 'black' }) {
  return (
    <div className='flex flex-row justify-center'>
      <FaSpinner className='animate-spin' size={size} fill={fill} />
    </div>
  )
}

export default Spinner
