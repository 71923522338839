// https://github.com/joeythelantern/React-Firebase-9/blob/main/src/components/AuthRoute.tsx
import { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { onValue, ref } from 'firebase/database'
import { auth, db } from '../../firebaseAssets'
import GlobalContext from '../../context/GlobalContext'
import Spinner from '../icons-logo/Spinner'

const AuthAndDataAndColor = ({ children }) => {
  const navigate = useNavigate()
  const { dispatch, settings } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log('AuthAndDataAndColor.jsx - useEffect()')
    const unsubOnAuth = onAuthStateChanged(auth, user => {
      if (user) {
        // console.log("  user:", user);
        const userRef = ref(db, `users/${user.uid}`)
        // TODO - Change get() to onValue() to get data pushed from Firebase.
        //        Will using onValue make components refresh twice since component level useState is also employed?
        onValue(userRef, snapshot => {
          const userData = snapshot.val()
          if (userData) {
            console.log('  setting global state ...')
            // TODO - change to settings only cuz route todo will handle todos but also have to fix nav go get todos' titles, groups, ids from db.
            // dispatch({ type: "SET_USER_SETTINGS", payload: userData.settings });
            dispatch({ type: 'SET_USER_DATA', payload: userData })
            setIsLoading(false)
          }
        })
        // get(userRef)
        //   .then(snapshot => {
        //     const userData = snapshot.val();
        //     if (userData) {
        //       console.log("  setting global state ...");
        //       // TODO - change to settings only cuz route todo will handle todos but also have to fix nav go get todos' titles, groups, ids from db.
        //       // dispatch({ type: "SET_USER_SETTINGS", payload: userData.settings });
        //       dispatch({ type: "SET_USER_DATA", payload: userData });
        //       setIsLoading(false);
        //     }
        //   })
        //   .catch(e => {
        //     console.log("DbToStore() - get() - error: ", e);
        //   });
      } else {
        console.log('  no user so sending /home ...')
        navigate('/home')
      }
    })
    return () => unsubOnAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log('AuthAndDataAndColor.jsx - useEffect() [settings.isDark]')
    const rootHTMLclasses = document.getElementById('rootHTMLtag')?.classList
    if (rootHTMLclasses) {
      rootHTMLclasses.remove('dark')
      if (settings.isDark) {
        rootHTMLclasses.add('dark')
      }
    }
  }, [settings.isDark])

  if (isLoading)
    return (
      <div className='pt-40 pb-40'>
        <Spinner size='4rem' fill='#fb923c' />
      </div>
    )

  return <>{children}</>
}

export default AuthAndDataAndColor
