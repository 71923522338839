import logo from '../../assets/logos/rabbit-white-96x96.png'

// TODO Change padding to margin cuz padding affects the size.
function Logo({ padding = 'p-2', size = '40px', isBackground = true }) {
  return (
    <>
      {isBackground ? (
        <div className={padding + ' bg-menu rounded-full'}>
          <img src={logo} alt='logo' width={size} />
        </div>
      ) : (
        <img src={logo} alt='logo' width={size} />
      )}
    </>
  )
}
export default Logo
