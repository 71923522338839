import { useContext, useEffect, useState } from 'react'
import GlobalContext from '../context/GlobalContext'
import { updateJotTitle } from '../context/GlobalActions'

function HeaderJot({ jotId }) {
  const { jots } = useContext(GlobalContext)
  const [title, setTitle] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [formData, setFormData] = useState({ newTitle: '' })
  const { newTitle } = formData

  useEffect(() => {
    if (jotId.length > 0 && jots[jotId] && jots[jotId]['title']) {
      const jotTitle = jots[jotId]['title']
      setTitle(jotTitle)
      setFormData({ newTitle: jotTitle })
    } else {
      setTitle('')
    }
    // eslint-disable-next-line
  }, [jotId, jots])

  const onSubmit = () => {
    console.log('HeaderJot.jsx - onSubmit()')
    setShowForm(false)
    updateJotTitle(jots[jotId], jotId, newTitle)
  }

  const onChange = e => {
    setFormData({ newTitle: e.target.value })
  }

  return (
    <div className='sm:h-20 sm:max-h-20 custom-min-h-20 w-full bg-menu dark:bg-dark-menu flex flex-row items-center justify-center'>
      {showForm ? (
        <form onSubmit={onSubmit}>
          <div className='p-2 flex flex-col md:flex-row items-center justify-center'>
            <input
              required
              id='newTitle'
              type='text'
              className='text-black w-60 px-3 py-1'
              value={newTitle}
              onChange={onChange}
            />
            <div className='pt-2 md:pt-0 flex flex-row items-center justify-center'>
              <button className='bg-success ml-2 px-3 py-1 rounded'>
                <span className='text-white text-l'>Update</span>
              </button>
              <button
                className='bg-danger ml-2 px-3 py-1 rounded'
                onClick={() => setShowForm(false)}
              >
                <span className='text-white text-l'>Cancel</span>
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div
          className='text-3xl cursor-pointer'
          onClick={() => {
            setShowForm(true)
          }}
        >
          {title}
        </div>
      )}
    </div>
  )
}

export default HeaderJot
